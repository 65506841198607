var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Stage", outlined: "", expanded: !_vm.is_stage_linked } },
    [
      _vm.is_stage_linked
        ? _c(
            "div",
            {
              staticClass: "controls",
              attrs: { slot: "right" },
              slot: "right",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-connection" },
                  on: {
                    click: function ($event) {
                      return _vm.handleViewStage(_vm.linked_stage)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.linked_stage?.notes) + " ")]
              ),
              _c(
                "el-alert",
                {
                  attrs: { type: "success", "show-icon": "", closable: false },
                },
                [_vm._v(" Linked ")]
              ),
            ],
            1
          )
        : [
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "form-wrapper",
                attrs: {
                  "auto-complete": "on",
                  model: _vm.form,
                  "label-width": "160px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "New stage" } },
                  [
                    _c("el-switch", {
                      attrs: { disabled: _vm.stages.length === 0 },
                      model: {
                        value: _vm.form.is_new,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_new", $$v)
                        },
                        expression: "form.is_new",
                      },
                    }),
                  ],
                  1
                ),
                _vm.form.is_new
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Notes", prop: "notes" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.notes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "notes", $$v)
                              },
                              expression: "form.notes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Start date", prop: "start_date" } },
                        [
                          _c("el-date-picker", {
                            model: {
                              value: _vm.form.start_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "start_date", $$v)
                              },
                              expression: "form.start_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Deadline", prop: "deadline_date" } },
                        [
                          _c("el-date-picker", {
                            model: {
                              value: _vm.form.deadline_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deadline_date", $$v)
                              },
                              expression: "form.deadline_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Stage",
                          prop: "id",
                          rules: {
                            required: true,
                            message: "Stage is required",
                            trigger: "blur",
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.form.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "id", $$v)
                              },
                              expression: "form.id",
                            },
                          },
                          _vm._l(_vm.stages, function (s) {
                            return _c(
                              "el-option",
                              {
                                key: s.id,
                                attrs: { label: `#${s.number}`, value: s.id },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "13px",
                                      "margin-right": "30px",
                                    },
                                  },
                                  [_vm._v(" Stage #" + _vm._s(s.number) + " ")]
                                ),
                                s.notes
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#8492a6",
                                          "font-size": "11px",
                                          float: "right",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(s.notes) + " ")]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "section-footer" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      loading: _vm.loading,
                      size: "mini",
                      icon: "el-icon-bottom",
                    },
                    on: { click: _vm.handleAssignStage },
                  },
                  [_vm._v(" Assign stage ")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }