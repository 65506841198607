var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Tasks linking", expanded: false } },
    [
      _c(
        "div",
        { attrs: { slot: "right" }, slot: "right" },
        [
          _vm.is_estimate_ticketed
            ? _c(
                "el-alert",
                {
                  attrs: { type: "success", "show-icon": "", closable: false },
                },
                [_vm._v(" Tasks have been created ")]
              )
            : !_vm.can_create
            ? _c("el-alert", { attrs: { type: "warning", closable: false } }, [
                _vm._v(
                  " All groups must be linked to components before you can create task links "
                ),
              ])
            : _vm.loading
            ? _c("el-alert", { attrs: { type: "info", closable: false } }, [
                _vm._v(" Generating, you will be notified when complete "),
              ])
            : [
                _vm.is_jira_available
                  ? _c(
                      "el-button",
                      {
                        attrs: { plain: "", disabled: _vm.loading },
                        on: { click: _vm.handleCreateJiraTickets },
                      },
                      [_vm._v(" Create Jira tickets ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", disabled: _vm.loading },
                    on: { click: _vm.handleCreateTodos },
                  },
                  [_vm._v(" Create TODOs ")]
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }