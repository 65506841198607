var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    {
      attrs: {
        title: _vm.group.title,
        outlined: "",
        expanded: !_vm.linked_tracking_component,
      },
    },
    [
      _c("svgicon", {
        staticClass: "jira-icon",
        attrs: { slot: "title_content_prefix", name: "jira-epic" },
        slot: "title_content_prefix",
      }),
      _vm.linked_tracking_component
        ? _c(
            "div",
            {
              staticClass: "controls",
              attrs: { slot: "right" },
              slot: "right",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-connection" },
                  on: {
                    click: function ($event) {
                      return _vm.handleViewComponent(
                        _vm.linked_tracking_component
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.linked_tracking_component?.name) + " "
                  ),
                ]
              ),
              _c(
                "el-alert",
                {
                  attrs: { type: "success", "show-icon": "", closable: false },
                },
                [_vm._v(" Linked ")]
              ),
            ],
            1
          )
        : [
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "form-wrapper",
                attrs: {
                  "auto-complete": "on",
                  model: _vm.form,
                  "label-width": "160px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "New component" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.form.is_new,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_new", $$v)
                        },
                        expression: "form.is_new",
                      },
                    }),
                  ],
                  1
                ),
                _vm.linked_retainer_component
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "Linked to retainer" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.linked_retainer_component.name) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.form.is_new
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Name",
                            prop: "name",
                            rules: {
                              required: true,
                              message: "Name is required",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Start date",
                            prop: "start_date",
                            rules: {
                              required: true,
                              message: " ",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-date-picker", {
                            model: {
                              value: _vm.form.start_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "start_date", $$v)
                              },
                              expression: "form.start_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "End date",
                            prop: "end_date",
                            rules: {
                              required: true,
                              message: " ",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-date-picker", {
                            model: {
                              value: _vm.form.end_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "end_date", $$v)
                              },
                              expression: "form.end_date",
                            },
                          }),
                        ],
                        1
                      ),
                      !_vm.linked_retainer_component
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Round time to",
                                  prop: "round",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "minutes",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.round,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "round", $$v)
                                      },
                                      expression: "form.round",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "append" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("pluralize")(
                                              "minute",
                                              _vm.form.round
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm.form.round > 0
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Round after",
                                      prop: "min",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: {
                                          placeholder: "minutes",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.min,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "min", $$v)
                                          },
                                          expression: "form.min",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "append" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("pluralize")(
                                                  "minute",
                                                  _vm.form.min
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Component",
                            prop: "id",
                            rules: {
                              required: true,
                              message: "Component is required",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.form.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "id", $$v)
                                },
                                expression: "form.id",
                              },
                            },
                            [
                              _vm._l(_vm.linkable_components, function (c) {
                                return _c("el-option", {
                                  key: c.id,
                                  attrs: { label: c.name, value: c.id },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Increase hours" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.form.add_hours,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "add_hours", $$v)
                                },
                                expression: "form.add_hours",
                              },
                            },
                            [
                              _vm._v(
                                " Add " +
                                  _vm._s(_vm.group_time_hours) +
                                  " " +
                                  _vm._s(
                                    _vm._f("pluralize")(
                                      "hour",
                                      _vm.group_time_hours
                                    )
                                  ) +
                                  " to component "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "section-footer" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      loading: _vm.loading,
                      size: "mini",
                      icon: "el-icon-bottom",
                    },
                    on: { click: _vm.handleAssignComponent },
                  },
                  [_vm._v(" Assign component ")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }