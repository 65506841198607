var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.estimate
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("el-button", { on: { click: _vm.goBackAction } }, [
                _vm._v("Back"),
              ]),
              _c("span", { staticClass: "ref" }, [
                _vm._v(" / "),
                _c("span", [_vm._v(" " + _vm._s(_vm.estimate.ref))]),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("estimate-component-assignment", {
            attrs: { estimate: _vm.estimate },
          }),
          _c("estimate-task-linking", { attrs: { estimate: _vm.estimate } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }