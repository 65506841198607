var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Component assignment" } },
    [
      _c("estimate-stage-link", { attrs: { estimate: _vm.estimate } }),
      _vm.estimate.stage
        ? _vm._l(_vm.sorted_groups, function (group) {
            return _c("estimate-component-link", {
              key: group.id,
              attrs: { estimate: _vm.estimate, group: group },
            })
          })
        : _c("el-alert", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              description: "A stage is required to assign components.",
              closable: false,
            },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }